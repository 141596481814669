import React, { useState, FunctionComponent } from 'react';
import { identity, compact, pickBy } from 'lodash';
import type { Role } from '@socrata/core-roles-api';

import { ConnectionConfig } from 'authentication/types';

import Form from 'common/components/Forms/Form';
import Button, { VARIANTS } from 'common/components/Button';

import Info from './Info';
import ConnectionList from './ConnectionList';

interface Props {
  roles: Role[];
}

/** An editor for the "auth0_forced_connections" auth0 config */
const Editor: FunctionComponent<Props> = ({ roles }) => {
  /** Update a value for an input in the state */
  const onUpdateValue =
    (
      connections: ConnectionConfig[],
      setConnections: React.Dispatch<React.SetStateAction<ConnectionConfig[]>>
    ) =>
    (connectionIndex: number, valueKey: string) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setConnections(
        connections.map((connection: ConnectionConfig, index: number) =>
          index === connectionIndex ? { ...connection, [valueKey]: e.target.value } : connection
        )
      );
    };

  /** Called when the role id dropdown changes */
  const onUpdateRoleId =
    (
      connections: ConnectionConfig[],
      setConnections: React.Dispatch<React.SetStateAction<ConnectionConfig[]>>
    ) =>
    (connectionIndex: number, roleId: number) => {
      setConnections(
        connections.map((connection: ConnectionConfig, index: number) =>
          index === connectionIndex ? { ...connection, roleId } : connection
        )
      );
    };

  const onUpdateIgnoreEmailRegexOnLoginPage =
    (
      connections: ConnectionConfig[],
      setConnections: React.Dispatch<React.SetStateAction<ConnectionConfig[]>>
    ) =>
    (connectionIndex: number, ignoreEmailRegexOnLoginPage: boolean) => {
      setConnections(
        connections.map((connection: ConnectionConfig, index: number) =>
          index === connectionIndex ? { ...connection, ignoreEmailRegexOnLoginPage } : connection
        )
      );
    };

  /** Clicking the button to add another connection */
  const onAddConnection = (
    connections: ConnectionConfig[],
    setConnections: React.Dispatch<React.SetStateAction<ConnectionConfig[]>>
  ) => {
    setConnections([
      ...connections,
      { connection: '', alias: '', match: '', roleId: -1, ignoreEmailRegexOnLoginPage: false }
    ]);
  };

  /** Clicking the button to remove a connection */
  const onRemoveConnection =
    (
      connections: ConnectionConfig[],
      setConnections: React.Dispatch<React.SetStateAction<ConnectionConfig[]>>
    ) =>
    (index: number) => {
      setConnections([...connections.slice(0, index), ...connections.slice(index + 1)]);
    };

  /**
   * Take the current state and apply it as the value of the input box, so the user can click save
   */
  const applyChanges = (connections: ConnectionConfig[]) => {
    // we want to omit any empty ones and only choose the values we actually want to persist
    const cleanConnections = compact(
      connections.map(
        ({ connection, alias, match, roleId, ignoreEmailRegexOnLoginPage }) =>
          connection &&
          pickBy(
            {
              connection,
              alias,
              match,
              roleId,
              ignoreEmailRegexOnLoginPage
            },
            identity
          )
      )
    );

    // set value of the dom node
    const inputDomNode = document.getElementById('properties_auth0_forced_connections') as HTMLInputElement;

    if (!inputDomNode) {
      throw new Error('Auth0 connections config (#properties_auth0_forced_connections) dom node not found!');
    }

    inputDomNode.value = JSON.stringify(cleanConnections);
  };

  const getDefaultState = (): ConnectionConfig[] => {
    const inputDomNode = document.getElementById('properties_auth0_forced_connections') as HTMLInputElement;

    if (!inputDomNode) {
      throw new Error('Auth0 connections config (#properties_auth0_forced_connections) dom node not found!');
    }

    // make sure we have defaults for everything or React complains
    return JSON.parse(inputDomNode.value).map((connection: ConnectionConfig) => ({
      // `...connection` spread below will overwrite all of these?
      // @ts-expect-error TS(2783) FIXME: 'connection' is specified more than once, so this ... Remove this comment to see the full error message
      connection: '',
      alias: '',

      // @ts-expect-error TS(2783) FIXME: 'match' is specified more than once, so this usage... Remove this comment to see the full error message
      match: '',
      roleId: '',
      regexTest: '',
      ...connection
    }));
  };

  const [forcedConnections, setForcedConnections] = useState(getDefaultState());

  const rolesDropdownOptions = [
    { title: '(none)', value: '' }, // no default role is okay
    ...roles.map((role) => ({
      title: `${role.name} (${role.id})`,
      value: role.id
    }))
  ];

  return (
    <Form>
      <Info />
      <ConnectionList
        rolesDropdownOptions={rolesDropdownOptions}
        forcedConnections={forcedConnections}
        onUpdateValue={onUpdateValue(forcedConnections, setForcedConnections)}
        onUpdateRoleId={onUpdateRoleId(forcedConnections, setForcedConnections)}
        onUpdateIgnoreEmailRegexOnLoginPage={onUpdateIgnoreEmailRegexOnLoginPage(
          forcedConnections,
          setForcedConnections
        )}
        onRemoveConnection={onRemoveConnection(forcedConnections, setForcedConnections)}
      />
      <b>
        When done editing, click the "Apply Connections" button to change the JSON in the input field.{' '}
        <span style={{ color: 'red' }}>
          You will still have to click "Save" below to persist the changes.
        </span>
      </b>
      <div>
        <Button
          onClick={() => onAddConnection(forcedConnections, setForcedConnections)}
          style={{ marginRight: '15px' }}
        >
          Add Connection...
        </Button>
        <Button variant={VARIANTS.PRIMARY} onClick={() => applyChanges(forcedConnections)}>
          Apply Connections
        </Button>
      </div>
    </Form>
  );
};

export default Editor;
